const en = {
	translation: {
		'home.metaTitle': 'Inicio - SYFRAS',
		'terms.metaTitle': 'Terminos - SYFRAS',
		'privacy.metaTitle': 'Privacidad - SYFRAS',
		'aup.metaTitle': 'AUP - SYFRAS',
		'cookies.metaTitle': 'Cookies - SYFRAS',

		'header.item1': 'Inicio',
		'header.item2': 'Precios',
		'header.item3': 'Productos',
		'header.item4': 'Características',
		'header.item5': 'Tecnologías',
		'header.item6': 'Testimonios',

		'fullScreen.title': 'Expándete en línea',
		'fullScreen.subtitle': 'con tranquilidad',
		'fullScreen.text': 'Nuestro equipo de profesionales con experiencia se dedica a proporcionar confiaza, soluciones de alojamiento de alto rendimiento para tu negocio. Desde alojamiento web hasta VPS y soluciones en la nube, tenemos la experiencia y los recursos para garantizar que su presencia en línea esté siempre en funcionamiento sin problemas.',
		'fullScreen.ctaText': 'Empieza ahora',
		'fullScreen.secondaryCtaText': 'Descubre Más',
		'fullScreen.tooltipText': 'Empezando en 2$/mes',

		'pricing.title': 'Planes para todos los negocios',
		'pricing.subtitle': 'Pasa de una sola página de aterrizaje a un imperio SaaS, hay un plan hecho a tu medida. Todos los planes son personalizables bajo demanda.',
		'pricing.month': 'mes',
		'pricing.months': 'meses',
		'pricing.year': 'año',
		'pricing.years': 'años',
		'pricing.mostPopular': 'Más Flexible',
		'pricing.plan1.name': 'Startup',
		'pricing.plan1.description1': '1 Dominio',
		'pricing.plan1.description2': '10GB de espacio en disco SSD',
		'pricing.plan1.description3': '5 Bases de datos',
		'pricing.plan2.name': 'Archivos',
		'pricing.plan2.description1': '10 Dominios',
		'pricing.plan2.description2': '40GB de espacio en disco SSD',
		'pricing.plan2.description3': '20 Bases de Datos ',
		'pricing.plan2.description4': 'Correos Ilimitados',
		'pricing.plan2.description5': 'Subdominios Ilimitados',
		'pricing.plan3.name': 'SaaS',
		'pricing.plan3.description1': 'Dominios Ilimitados',
		'pricing.plan3.description2': '80GB de espacio en disco SSD',
		'pricing.plan3.description3': 'Base de datos Ilimitada',
		'pricing.ctaText': 'Empieza tu Página Web',

		'products.title': 'Todos nuestros servicios',
		'products.ctaText': 'Explora nuestras Opciones',
		'products.description': 'Ofrecemos variedades de servicios para satisfacer las necesidades de todas las empresas',
		'products.product1.title': 'Hosting Compartido',
		'products.product1.description': 'Administramos PHP, Ruby y Node.JS Hosting para sitios web',
		'products.product2.title': 'Servidores Virtuales Privados',
		'products.product2.description': 'Todo el sistema administrado por ti. Muy pronto.',
		'products.product3.title': 'Revendedor',
		'products.product3.description': 'Vende nuestros como tuyos.',

		'features.title': 'Características',
		'features.subtitle': 'Su negocio y sus datos son nuestra primera prioridad. Usamos la mejor tecnología disponible para mantenerlo seguro y entregarlo rápido.',
		'features.feature1.title': 'Copias de seguridad diarias',
		'features.feature1.description': 'Hacemos copias de seguridad diarias de sus datos para que pueda estar tranquilo en caso de imprevistos.',
		'features.feature2.title': 'Alojado en Europa',
		'features.feature2.description': 'Tanto sus datos como los de sus clientes cumplirán con las regulaciones de GDPR cuando estén alojados en nuestros servidores.',
		'features.feature3.title': 'DNS de Marca Administrada',
		'features.feature3.description': 'Aloja tu DNS en tu propio dominio para una apariencia más profesional, con registros creados automáticamente.',
		'features.feature4.title': 'Cifrado de datos',
		'features.feature4.description': 'Todos los sitios web tienen HTTPS habilitado automáticamente de forma gratuita una vez que su dominio esté alojado con nosotros, por lo que sus datos están protegidos.',
		'features.feature5.title': 'Migración Gratis',
		'features.feature5.description': 'Migraremos su página web desde su proveedor actual de forma gratuita.',

		'technologies.title': 'Tecnologías',
		'technologies.subtitle': 'Solo lo mejor disponible para brindarle rendimiento y tranquilidad',
		'technologies.techno1.title': 'Cloud Linux',
		'technologies.techno1.description': 'Un sistema operativo fiable y eficiente diseñado para optimizar el rendimiento de los servidores web y proporcionar un entorno de alojamiento estable.',
		'technologies.techno2.title': 'Litespeed',
		'technologies.techno2.description': 'Un servidor web de alto rendimiento que está diseñado para manejar tráfico pesado y ofrecer tiempos de carga rápidos para sitios web.',
		'technologies.techno3.title': 'Plesk',
		'technologies.techno3.description': 'Un panel de control completo y fácil de usar que permite a los usuarios administrar y mantener fácilmente sus sitios web, servidores y dominios.',
		'technologies.techno4.title': 'Nginx',
		'technologies.techno4.description': 'A high-performance reverse proxy and web server that can handle large amounts of traffic and deliver fast loading times for websites.',
		'technologies.techno5.title': 'Apache',
		'technologies.techno5.description': 'A reliable, flexible web server with a wide range of features.',
		'technologies.techno6.title': 'Google PageSpeed',
		'technologies.techno6.description': 'A powerful tool to optimize the performance of websites by analyzing and providing recommendations for improving loading speed and user experience.',
		'technologies.techno7.title': 'WordPress',
		'technologies.techno7.description': 'A user-friendly and feature-rich content management system that makes it easy to create and manage websites, blogs, and online stores.',
		'technologies.techno8.title': 'PHP',
		'technologies.techno8.description': 'A popular programming language that is widely used for building dynamic and interactive websites.',
		'technologies.techno9.title': 'NodeJS',
		'technologies.techno9.description': 'An efficient runtime environment for building scalable and high-performance web applications.',
		'technologies.techno10.title': 'Ruby',
		'technologies.techno10.description': 'An expressive programming language that is known for its simplicity and elegance.',
		'technologies.techno11.title': 'BTCPay Server',
		'technologies.techno11.description': 'We accept crypto payments through BTCPay Server in Bitcoin, Litecoin and Monero.',
		'technologies.techno12.title': 'Docker',
		'technologies.techno12.description': 'A powerful and efficient containerization platform. If you need something that is not available on our servers, we can provide it with Docker.',
		'technologies.techno13.title': 'MariaDB',
		'technologies.techno13.description': 'An open-source database management system that is known for its reliability, performance, and flexibility.',
		'technologies.techno14.title': 'PostgreSQL',
		'technologies.techno14.description': 'A highly customizable and feature-rich open-source database management system.',
		'technologies.techno15.title': 'SolusVM',
		'technologies.techno15.description': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.',

		'testimonials.title.part1': 'Nuestros clientes',
		'testimonials.title.part2': 'Comentan',
		'testimonials.subtitle': 'Trabajamos con clientes alrededor del mundo',

		'footer.copyrightText': 'Todos los derechos reservados',
		'footer.item1': 'Terminos y Condiciones',
		'footer.item2': 'Política de Privacidad',
		'footer.item3': 'Política de Cookies',
		'footer.item4': 'Política de Uso Aceptable',
		'footer.subscriptionInput.placeholder': 'Tu dirección de correo electrónico',
		'footer.subscriptionCta.text': 'Suscríbete',
	}
}

export default en