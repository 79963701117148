const it = {
	translation: {
		'home.metaTitle': 'Inizio - SYFRAS',
		'terms.metaTitle': 'Termini - SYFRAS',
		'privacy.metaTitle': 'Privacy - SYFRAS',
		'aup.metaTitle': 'AUP - SYFRAS',
		'cookies.metaTitle': 'Cookies - SYFRAS',

		'header.item1': 'Inizio',
		'header.item2': 'Prezzi',
		'header.item3': 'Prodotti',
		'header.item4': 'Vantaggi',
		'header.item5': 'Tecnologia',
		'header.item6': 'Testimonianze',

		'fullScreen.title': 'Cresci online',
		'fullScreen.subtitle': 'serenamente',
		'fullScreen.text': 'Il nostro team di professionisti esperti a disposizione per garantire soluzioni di hosting affidabili e all\'avanguardia per il vostro business. Dal web hosting a VPS e soluzioni in cloud, abbiamo le conoscenze e risorse che ti garantiscono di essere senpre presente e attivo online senza intoppi.',
		'fullScreen.ctaText': 'Inizia Ora',
		'fullScreen.secondaryCtaText': 'Scopri di piú',
		'fullScreen.tooltipText': 'A partire da 2€/mese',

		'pricing.title': 'Piani per ogni business',
		'pricing.subtitle': 'Da una sola pagina a un impero del SaaS, c\'é un piano su misura per te. Tutti i piani sono personalizzabili su rishiesta.',
		'pricing.month': 'mese',
		'pricing.months': 'mesi',
		'pricing.year': 'anno',
		'pricing.years': 'anni',
		'pricing.mostPopular': 'Flessibile',
		'pricing.plan1.name': 'Startup',
		'pricing.plan1.description1': '1 Dominio',
		'pricing.plan1.description2': '10G SSD di Spazio',
		'pricing.plan1.description3': '5 Database',
		'pricing.plan2.name': 'Media',
		'pricing.plan2.description1': '10 Domini',
		'pricing.plan2.description2': '40G SSD di Spazio',
		'pricing.plan2.description3': '20 Database',
		'pricing.plan2.description4': 'Email Illimitate',
		'pricing.plan2.description5': 'Subdomini Illimitati',
		'pricing.plan3.name': 'SaaS',
		'pricing.plan3.description1': 'Domini Illimitati',
		'pricing.plan3.description2': '80G SSD di Spazio',
		'pricing.plan3.description3': 'Database Illimitati',
		'pricing.ctaText': 'Inizia il tuo Sito Web',

		'products.title': 'La nostra Offerta',
		'products.ctaText': 'Esplora le nostre opzioni',
		'products.description': 'Offriamo una gamma di servizi che soddisfano le esigenze di qualsiasi azienda.',
		'products.product1.title': 'Hosting Condiviso',
		'products.product1.description': 'Hosting gestito per PHP, Ruby e Node.JS per siti web.',
		'products.product2.title': 'Server Virtuali Privati',
		'products.product2.description': 'Un\'intera struttura gestita da te. Prossimamente.',
		'products.product3.title': 'Rivenditore',
		'products.product3.description': 'Vendi i nostri servizi come fossero i tuoi.',

		'features.title': 'Vantaggi',
		'features.subtitle': 'La tua impresa e i tuoi dati sono la nostra massima prioritá. Usiamo le migliori tecnologie disponibili per mantenerli protetti e fornirli rapidamente.',
		'features.feature1.title': 'Backup Giornalieri',
		'features.feature1.description': 'Eseguiamo il backup dei tuoi dati ogni giorno cosi da poter stare sereno anche in caso di imprevisti.',
		'features.feature2.title': 'Ospitato in Europa',
		'features.feature2.description': 'Preserveremo i tuoi dati e quelli dei tuoi clienti rispettando le norme GDPR vigenti.',
		'features.feature3.title': 'DNS a Marchio',
		'features.feature3.description': 'Ospita i tuoi DNS sul tuo dominio personale per dare un\'immagine piú professionale.',
		'features.feature4.title': 'Crittografia dei Dati',
		'features.feature4.description': 'Lasciaci ospitare il tuoi dominio, e ti offriamo connessione HTTPS automatica gratuita.',
		'features.feature5.title': 'Migrazione Gratuita',
		'features.feature5.description': 'Effettueremo la migrazione della tua applicazione dal tuo provider attuale senza alcun costo.',

		'technologies.title': 'Tecnologie',
		'technologies.subtitle': 'Solo il meglio a disposizione per darti performance e serenitá',
		'technologies.techno1.title': 'Cloud Linux',
		'technologies.techno1.description': 'Un sistema operativo affidabile ed efficiente pensato per ottimizzare la performance dei server di rete e fornire un ambiente di hosting stabile.',
		'technologies.techno2.title': 'Litespeed',
		'technologies.techno2.description': 'Un server di rete ad alte prestazioni disegnato per gestire un traffico intenso e fornire tempi di caricamento veloci per siti web.',
		'technologies.techno3.title': 'Plesk',
		'technologies.techno3.description': 'Un pannello di controllo completo e intuitivo che permette agli utenti di gestire e mantenere con facilita i loro siti web, server e domini.',
		'technologies.techno4.title': 'Nginx',
		'technologies.techno4.description': 'A high-performance reverse proxy and web server that can handle large amounts of traffic and deliver fast loading times for websites.',
		'technologies.techno5.title': 'Apache',
		'technologies.techno5.description': 'Un server di rete affidabile e flessibile con un ampia gamma di funzioni.',
		'technologies.techno6.title': 'Google PageSpeed',
		'technologies.techno6.description': 'Un potente strumento per ottimizzare la performance dei siti web, fornisce analisi e consigli per migliorare la velocita di caricamento dati e l\'esperienza dell\'utente.',
		'technologies.techno7.title': 'WordPress',
		'technologies.techno7.description': 'Un sistema di gestione contenuti facile da usare e ricco di funzioni che semplifica la creazione e gestione di siti web, blog e negozi online.',
		'technologies.techno8.title': 'PHP',
		'technologies.techno8.description': 'Un linguaggio di programmazione diffuso e utilizzato comunemente per costruire siti web dinamici e interattivi.',
		'technologies.techno9.title': 'NodeJS',
		'technologies.techno9.description': 'Un ambiente di esecuzione efficiente per costruire applicazioni web scalabili e ad alte prestazioni.',
		'technologies.techno10.title': 'Ruby',
		'technologies.techno10.description': 'Un linguaggio di programmazione espressivo rinomato per la sua semplicita ed eleganza.',
		'technologies.techno11.title': 'BTCPay Server',
		'technologies.techno11.description': 'Accettiamo pagamenti in criptovalute tramite BTCPay in Bitcoin, Litecoin e Monero.',
		'technologies.techno12.title': 'Docker',
		'technologies.techno12.description': 'A powerful and efficient containerization platform. If you need something that is not available on our servers, we can provide it with Docker.',
		'technologies.techno13.title': 'MariaDB',
		'technologies.techno13.description': 'An open-source database management system that is known for its reliability, performance, and flexibility.',
		'technologies.techno14.title': 'PostgreSQL',
		'technologies.techno14.description': 'A highly customizable and feature-rich open-source database management system.',
		'technologies.techno15.title': 'SolusVM',
		'technologies.techno15.description': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.',

		'testimonials.title.part1': 'I Nostri Clienti',
		'testimonials.title.part2': 'Dí la tua',
		'testimonials.subtitle': 'Lavoriamo con clienti da tutto il mondo',

		'footer.copyrightText': 'Tutti i diritti riservati',
		'footer.item1': 'Termini di Servizio',
		'footer.item2': 'Politica sulla Privacy',
		'footer.item3': 'Politica sui Cookie',
		'footer.item4': 'Politica di Uso Accettabile',
		'footer.subscriptionInput.placeholder': 'La Tua Email',
		'footer.subscriptionCta.text': 'Registrati',
	}
}

export default it