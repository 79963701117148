const en = {
	translation: {
		'home.metaTitle': 'Home - SYFRAS',
		'terms.metaTitle': 'Terms - SYFRAS',
		'privacy.metaTitle': 'Privacy - SYFRAS',
		'aup.metaTitle': 'AUP - SYFRAS',
		'cookies.metaTitle': 'Cookies - SYFRAS',

		'header.item1': 'Home',
		'header.item2': 'Pricing',
		'header.item3': 'Products',
		'header.item4': 'Features',
		'header.item5': 'Technologies',
		'header.item6': 'Testimonials',

		'fullScreen.title': 'Expand online',
		'fullScreen.subtitle': 'with peace of mind',
		'fullScreen.text': 'Our team of experienced professionals is dedicated to providing reliable, high-performance hosting solutions for your business. From web hosting to VPS and cloud solutions, we have the expertise and resources to ensure your online presence is always up and running smoothly.',
		'fullScreen.ctaText': 'Get started',
		'fullScreen.secondaryCtaText': 'Learn More',
		'fullScreen.tooltipText': 'Starting at 2€/mo',

		'pricing.title': 'Plans for all businesses',
		'pricing.subtitle': 'From a single page landing to a SaaS empire, there is a plan tailored for you. All plans are customizable on-demand.',
		'pricing.month': 'month',
		'pricing.months': 'months',
		'pricing.year': 'year',
		'pricing.years': 'years',
		'pricing.mostPopular': 'Most Flexible',
		'pricing.plan1.name': 'Startup',
		'pricing.plan1.description1': '1 Domain',
		'pricing.plan1.description2': '10G SSD Disk Space',
		'pricing.plan1.description3': '5 Databases',
		'pricing.plan2.name': 'Media',
		'pricing.plan2.description1': '10 Domains',
		'pricing.plan2.description2': '40G SSD Disk Space',
		'pricing.plan2.description3': '20 Databases',
		'pricing.plan2.description4': 'Unlimited Emails',
		'pricing.plan2.description5': 'Unlimited Subdomains',
		'pricing.plan3.name': 'SaaS',
		'pricing.plan3.description1': 'Unlimited Domains',
		'pricing.plan3.description2': '80G SSD Disk Space',
		'pricing.plan3.description3': 'Unlimited Databases',
		'pricing.ctaText': 'Start your Website',

		'products.title': 'Everything we provide',
		'products.ctaText': 'Explore our Options',
		'products.description': 'We offer a variety of services to fit the needs of all businesses',
		'products.product1.title': 'Shared Hosting',
		'products.product1.description': 'Managed PHP, Ruby and Node.JS hosting for websites',
		'products.product2.title': 'Virtual Private Servers',
		'products.product2.description': 'Entire system managed by you. Coming soon.',
		'products.product3.title': 'Reseller',
		'products.product3.description': 'Sell our services as your own.',

		'features.title': 'Features',
		'features.subtitle': 'Your business and your data are our top priority. We use the best technology available to keep it safe and deliver it fast.',
		'features.feature1.title': 'Daily Backups',
		'features.feature1.description': 'We backup your data every day so you can have have peace of mind in case of unforeseen events.',
		'features.feature2.title': 'Hosted in Europe',
		'features.feature2.description': 'Both your data and your customer\'s data will comply with GDPR regulations when hosted on our servers.',
		'features.feature3.title': 'Managed Branded DNS',
		'features.feature3.description': 'Host your DNS on your own domain for a more professional appearance, with records created automatically.',
		'features.feature4.title': 'Data Encryption',
		'features.feature4.description': 'All websites have HTTPS automatically enabled for free once your domain is hosted with us, so your data is protected.',
		'features.feature5.title': 'Free Migration',
		'features.feature5.description': 'We will migrate your application from your current provider for free.',

		'technologies.title': 'Technologies',
		'technologies.subtitle': 'Only the best available to give you performance and peace of mind',
		'technologies.techno1.title': 'Cloud Linux',
		'technologies.techno1.description': 'A reliable and efficient operating system designed to optimize the performance of web servers and provide a stable hosting environment.',
		'technologies.techno2.title': 'Litespeed',
		'technologies.techno2.description': 'A high-performance web server that is designed to handle heavy traffic and deliver fast loading times for websites.',
		'technologies.techno3.title': 'Plesk',
		'technologies.techno3.description': 'A comprehensive and user-friendly control panel that allows users to easily manage and maintain their websites, servers, and domains.',
		'technologies.techno4.title': 'Nginx',
		'technologies.techno4.description': 'A high-performance reverse proxy and web server that can handle large amounts of traffic and deliver fast loading times for websites.',
		'technologies.techno5.title': 'Apache',
		'technologies.techno5.description': 'A reliable, flexible web server with a wide range of features.',
		'technologies.techno6.title': 'Google PageSpeed',
		'technologies.techno6.description': 'A powerful tool to optimize the performance of websites by analyzing and providing recommendations for improving loading speed and user experience.',
		'technologies.techno7.title': 'WordPress',
		'technologies.techno7.description': 'A user-friendly and feature-rich content management system that makes it easy to create and manage websites, blogs, and online stores.',
		'technologies.techno8.title': 'PHP',
		'technologies.techno8.description': 'A popular programming language that is widely used for building dynamic and interactive websites.',
		'technologies.techno9.title': 'NodeJS',
		'technologies.techno9.description': 'An efficient runtime environment for building scalable and high-performance web applications.',
		'technologies.techno10.title': 'Ruby',
		'technologies.techno10.description': 'An expressive programming language that is known for its simplicity and elegance.',
		'technologies.techno11.title': 'BTCPay Server',
		'technologies.techno11.description': 'We accept crypto payments through BTCPay Server in Bitcoin, Litecoin and Monero.',
		'technologies.techno12.title': 'Docker',
		'technologies.techno12.description': 'A powerful and efficient containerization platform. If you need something that is not available on our servers, we can provide it with Docker.',
		'technologies.techno13.title': 'MariaDB',
		'technologies.techno13.description': 'An open-source database management system that is known for its reliability, performance, and flexibility.',
		'technologies.techno14.title': 'PostgreSQL',
		'technologies.techno14.description': 'A highly customizable and feature-rich open-source database management system.',
		'technologies.techno15.title': 'SolusVM',
		'technologies.techno15.description': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.',

		'testimonials.title.part1': 'Our clients',
		'testimonials.title.part2': 'Speak',
		'testimonials.subtitle': 'We work with clients around the world',

		'footer.copyrightText': 'All rights reserved',
		'footer.item1': 'Terms of Service',
		'footer.item2': 'Privacy Policy',
		'footer.item3': 'Cookie Policy',
		'footer.item4': 'Acceptable Usage Policy',
		'footer.subscriptionInput.placeholder': 'Your email address',
		'footer.subscriptionCta.text': 'Subscribe',
	}
}

export default en