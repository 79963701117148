import { Box, Flex, Text, IconButton, Stack, Collapse, Popover, PopoverTrigger, useColorModeValue, useDisclosure, Button, useColorMode, Menu, MenuButton, MenuItem, MenuList, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { FaUserAlt } from 'react-icons/fa'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import logoBlack from './../logo_black.svg'
import logoWhite from './../logo_white.svg'
import { useCookies } from 'react-cookie'

const Header = (props: any) => {
	const { t, i18n } = useTranslation()

	const NAV_ITEMS: Array<NavItem> = [
		{
			value: t('header.item1'),
			label: 'home'
		},
		{
			value: t('header.item2'),
			label: 'pricing'
		},
		{
			value: t('header.item3'),
			label: 'products'
		},
		{
			value: t('header.item4'),
			label: 'features'
		},
		{
			value: t('header.item5'),
			label: 'technologies'
		}
		// {
		// 	value: t('header.item6'),
		// label: 'testimonials'
		// }
	]

	const { isOpen, onToggle } = useDisclosure()
	const linkColor = useColorModeValue('black', 'white')
	const linkHoverColor = '#00fead'
	const { colorMode, toggleColorMode } = useColorMode()

	const [darkmodeHeaderBg, setDarkmodeHeaderBg] = useState('black')
	window.addEventListener('scroll', () => {
		if (window.scrollY > 0 && darkmodeHeaderBg !== 'grey.800') setDarkmodeHeaderBg('gray.800')
		else if (window.scrollY === 0 && darkmodeHeaderBg !== 'black') setDarkmodeHeaderBg('black')
	})

	const [, setCookie] = useCookies(['syfras-displaymode'])

	return (
		<Box position='fixed' w='100%' zIndex={2}>
			<Flex backgroundColor={useColorModeValue('white', darkmodeHeaderBg)} color={useColorModeValue('gray.600', 'white')} minH={'60px'} py={{ base: 2 }} px={{ base: 4 }} borderBottom={useColorModeValue(1, darkmodeHeaderBg === 'black' ? 0 : 1)} borderStyle={'solid'} borderColor={useColorModeValue('gray.200', darkmodeHeaderBg === 'black' ? '' : 'gray.700')} align={'center'}>
				<Flex flex={{ base: 1, md: 'auto' }} width={0} ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
					<IconButton onClick={onToggle} icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />} variant={'ghost'} aria-label={'Toggle Navigation'} />
				</Flex>
				<Flex
					position={{ base: 'absolute', md: 'relative' }}
					left={{ base: '42.5px', md: '0' }}
					onClick={() => {
						if (window.location.pathname === '/') props.navigateSmoothly('')
					}}
				>
					<Link to={window.location.pathname === '/' ? '' : '/'}>
						{/* <Text id='logo' cursor='pointer' textAlign={useBreakpointValue({ base: 'center', md: 'left' })} fontFamily={'heading'} color={useColorModeValue('black', '#00fead')} ml={1}>
							Syfras
						</Text> */}
						{colorMode === 'light' ? <Image h='37px' src={logoBlack} /> : <Image h='37px' src={logoWhite} />}
					</Link>
				</Flex>
				<Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'center' }}>
					<Flex display={{ base: 'none', md: 'flex' }} ml={10}>
						<Stack direction={'row'} spacing={4}>
							{NAV_ITEMS.map((navItem) => (
								<Box key={navItem.label}>
									<Popover trigger={'hover'} placement={'bottom-start'}>
										<PopoverTrigger>
											<Flex
												cursor='pointer'
												p={2}
												onClick={() => props.navigateSmoothly(navItem.label)}
												fontSize={'sm'}
												fontWeight={700}
												color={linkColor}
												_hover={{
													textDecoration: 'none',
													color: linkHoverColor
												}}
											>
												{navItem.value}
											</Flex>
										</PopoverTrigger>
									</Popover>
								</Box>
							))}
						</Stack>
					</Flex>
				</Flex>
				<Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={4}>
					{/* <Button as={'a'} fontSize={'sm'} fontWeight={400} variant={'link'} href={'#'}>
						Sign In
					</Button> */}
					{/* <Button
						display={{ base: 'none', md: 'inline-flex' }}
						fontSize={'sm'}
						fontWeight={600}
						color={'black'}
						bg={'#00fead'}
						//   href={'#'}
						_hover={
							{
								// bg: 'pink.300'
							}
						}
					>
						CTA espace client
					</Button> */}
					<Button aria-label='User login' ml='15' onClick={() => window.open('https://client.syfr.as')}>
						{<FaUserAlt color={useColorModeValue('black', 'white')} />}
						{/* <Text ml={2}>Texte facultatif</Text> */}
					</Button>
					<Menu id='language'>
						<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
							{i18n.language === 'en' ? '🇬🇧' : ''}
							{i18n.language === 'fr' ? '🇫🇷' : ''}
							{i18n.language === 'es' ? '🇪🇸' : ''}
							{i18n.language === 'it' ? '🇮🇹' : ''}
						</MenuButton>
						<MenuList>
							<MenuItem onClick={() => props.updateLang('en', i18n)} display='flex' justifyContent='space-between' w='100%'>
								<Text>🇬🇧</Text>EN
							</MenuItem>
							<MenuItem onClick={() => props.updateLang('fr', i18n)} display='flex' justifyContent='space-between' w='100%'>
								<Text>🇫🇷</Text>FR
							</MenuItem>
							<MenuItem onClick={() => props.updateLang('es', i18n)} display='flex' justifyContent='space-between' w='100%'>
								<Text>🇪🇸</Text>ES
							</MenuItem>
							<MenuItem onClick={() => props.updateLang('it', i18n)} display='flex' justifyContent='space-between' w='100%'>
								<Text>🇮🇹</Text>IT
							</MenuItem>
						</MenuList>
					</Menu>

					<Button
						aria-label='Dark/light mode'
						ml='15'
						onClick={() => {
							setCookie('syfras-displaymode', colorMode === 'light' ? 'dark' : 'light')
							toggleColorMode()
						}}
					>
						{useColorModeValue(<MoonIcon color='black' />, <SunIcon />)}
					</Button>
				</Stack>
			</Flex>

			<Collapse in={isOpen} animateOpacity>
				<MobileNav NAV_ITEMS={NAV_ITEMS} navigateSmoothly={props.navigateSmoothly} />
			</Collapse>
		</Box>
	)
}

const MobileNav = (props: any) => {
	return (
		<Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
			{props.NAV_ITEMS.map((navItem: NavItem) => (
				<MobileNavItem navigateSmoothly={props.navigateSmoothly} key={navItem.label} {...navItem} />
			))}
		</Stack>
	)
}

const MobileNavItem = (props: any) => {
	// eslint-disable-next-line
	const { isOpen, onToggle } = useDisclosure()

	return (
		<Stack spacing={4} onClick={onToggle}>
			<Flex
				py={2}
				as={Link}
				onClick={(e) => {
					e.preventDefault()
					props.navigateSmoothly(props.label)
				}}
				justify={'space-between'}
				align={'center'}
				_hover={{
					textDecoration: 'none'
				}}
				to={''}
			>
				<Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
					{props.value}
				</Text>
			</Flex>
		</Stack>
	)
}

interface NavItem {
	label: string
	value: string
	navigate?: void
}

export default Header
