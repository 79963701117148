const fr = {
	translation: {
		'home.metaTitle': 'Accueil - SYFRAS',
		'terms.metaTitle': 'Termes - SYFRAS',
		'privacy.metaTitle': 'Vie Privée - SYFRAS',
		'aup.metaTitle': 'Utilisation - SYFRAS',
		'cookies.metaTitle': 'Cookies - SYFRAS',
		
		'header.item1': 'Accueil',
		'header.item2': 'Tarifs',
		'header.item3': 'Produits',
		'header.item4': 'Fonctionnalités',
		'header.item5': 'Technologies',
		'header.item6': 'Avis',

		'fullScreen.title': 'Hébergez votre site',
		'fullScreen.subtitle': 'serènement',
		'fullScreen.text': 'Notre équipe de professionels expérimentés est dédiée à vous fournir des solutions d\'hébergement  fiables et performantes pour votre business. De l\'hébergement web aux solutions cloud en passant par les VPS, nous avons les ressources et l\'expertise de garder votre présence en ligne efficace et fonctionnelle.',
		'fullScreen.ctaText': 'Lancez vous',
		'fullScreen.secondaryCtaText': 'En savoir plus',
		'fullScreen.tooltipText': 'À partir de 2€/mo',

		'pricing.title': 'Un plan pour chaque projet',
		'pricing.subtitle': 'D\'un site One Page à un empire de SaaS, il y a un plan pour vous. Tous les plans sont personalisables sur demande.',
		'pricing.month': 'mois',
		'pricing.months': 'mois',
		'pricing.year': 'an',
		'pricing.years': 'ans',
		'pricing.mostPopular': 'Plus Flexible',
		'pricing.plan1.name': 'Startup',
		'pricing.plan1.description1': '1 Nom de Domaine',
		'pricing.plan1.description2': '10G Mémoire SSD',
		'pricing.plan1.description3': '5 Bases de Données',
		'pricing.plan2.name': 'Media',
		'pricing.plan2.description1': '10 Noms de Domaine',
		'pricing.plan2.description2': '40G Mémoire SSD',
		'pricing.plan2.description3': '20 Bases de Données',
		'pricing.plan2.description4': 'Emails Illimités',
		'pricing.plan2.description5': 'Sous Domaines Illimités',
		'pricing.plan3.name': 'SaaS',
		'pricing.plan3.description1': 'Noms de Domaine Illimités',
		'pricing.plan3.description2': '80G Mémoire SSD',
		'pricing.plan3.description3': 'Bases de Données Illimitése',
		'pricing.ctaText': 'Lancez votre site',

		'products.title': 'Tous nos produits',
		'products.ctaText': 'Explorez nos Options',
		'products.description': 'Nous offrons une panoplie de services adaptée au besoin de toute entreprise',
		'products.product1.title': 'Hébergement Mutualisé',
		'products.product1.description': 'Hébergement autogéré de sites web PHP, Ruby et Node.JS',
		'products.product2.title': 'Virtual Private Servers',
		'products.product2.description': 'Serveurs gérés par vous. Bientôt disponible.',
		'products.product3.title': 'Revendeur',
		'products.product3.description': 'Vendez nos services en marque blanche',

		'features.title': 'Fonctionnalités',
		'features.subtitle': 'Votre entreprise et vos donnés sont notre priorité. Nous utilisons les meilleures technologies disponibles pour assurer leur sécurité.',
		'features.feature1.title': 'Sauvegardes Journalières',
		'features.feature1.description': 'Vos données sont stockés deux fois sur nos serveurs et sauvegardées hors-site chaque jour pour garantir votre tranquilité en cas de pépin.',
		'features.feature2.title': 'Hébergé en Europe',
		'features.feature2.description': 'Vos données et celles de vos clients sont hébergées en Europe et sont donc en règles avec les normes RGPD.',
		'features.feature3.title': 'DNS Marque Blanche',
		'features.feature3.description': 'Hébergez vos serveurs DNS sur votre propre nom de domaine pour une apparence professionelle.',
		'features.feature4.title': 'Chiffrement des Données',
		'features.feature4.description': 'Tous les sites sont automatiquement protégés avec un chiffrement HTTPS haut de gamme pour sécuriser vos données.',
		'features.feature5.title': 'Migration Gratuite',
		'features.feature5.description': 'Nous migrons votre application gratuitement depuis votre hébergeur actuel pour une migration sans coupure et sans prise de tête.',

		'technologies.title': 'Technologies',
		'technologies.subtitle': 'Le dernier cri des logiciels de sécurité et de performance',
		'technologies.techno1.title': 'Cloud Linux',
		'technologies.techno1.description': 'A reliable and efficient operating system designed to optimize the performance of web servers and provide a stable hosting environment.',
		'technologies.techno2.title': 'Litespeed',
		'technologies.techno2.description': 'A high-performance web server that is designed to handle heavy traffic and deliver fast loading times for websites.',
		'technologies.techno3.title': 'Plesk',
		'technologies.techno3.description': 'A comprehensive and user-friendly control panel that allows users to easily manage and maintain their websites, servers, and domains.',
		'technologies.techno4.title': 'Nginx',
		'technologies.techno4.description': 'A high-performance reverse proxy and web server that can handle large amounts of traffic and deliver fast loading times for websites.',
		'technologies.techno5.title': 'Apache',
		'technologies.techno5.description': 'A reliable, flexible web server with a wide range of features.',
		'technologies.techno6.title': 'Google PageSpeed',
		'technologies.techno6.description': 'A powerful tool to optimize the performance of websites by analyzing and providing recommendations for improving loading speed and user experience.',
		'technologies.techno7.title': 'WordPress',
		'technologies.techno7.description': 'A user-friendly and feature-rich content management system that makes it easy to create and manage websites, blogs, and online stores.',
		'technologies.techno8.title': 'PHP',
		'technologies.techno8.description': 'A popular programming language that is widely used for building dynamic and interactive websites.',
		'technologies.techno9.title': 'NodeJS',
		'technologies.techno9.description': 'An efficient runtime environment for building scalable and high-performance web applications.',
		'technologies.techno10.title': 'Ruby',
		'technologies.techno10.description': 'An expressive programming language that is known for its simplicity and elegance.',
		'technologies.techno11.title': 'BTCPay Server',
		'technologies.techno11.description': 'We accept crypto payments through BTCPay Server in Bitcoin, Litecoin and Monero.',
		'technologies.techno12.title': 'Docker',
		'technologies.techno12.description': 'A powerful and efficient containerization platform. If you need something that is not available on our servers, we can provide it with Docker.',
		'technologies.techno13.title': 'MariaDB',
		'technologies.techno13.description': 'An open-source database management system that is known for its reliability, performance, and flexibility.',
		'technologies.techno14.title': 'PostgreSQL',
		'technologies.techno14.description': 'A highly customizable and feature-rich open-source database management system.',
		'technologies.techno15.title': 'SolusVM',
		'technologies.techno15.description': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.',

		'testimonials.title.part1': 'Nos clients',
		'testimonials.title.part2': 'Parlent',
		'testimonials.subtitle': 'We work with clients around the world',

		'footer.copyrightText': 'Tous droits réservés',
		'footer.item1': 'Termes & Conditions',
		'footer.item2': 'Police de Vie Privée',
		'footer.item3': 'Police de Cookies',
		'footer.item4': 'Police d\'Utilisation',
		'footer.subscriptionInput.placeholder': 'Votre email',
		'footer.subscriptionCta.text': 'Inscrivez vous',
	}
}

export default fr