import './index.css'
import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom'
import { Button, ChakraProvider, useColorMode, useColorModeValue } from '@chakra-ui/react'
import Header from './components/Header'
import FullScreen from './components/FullScreen'
import Pricing from './components/Pricing'
import Products from './components/Products'
import Testimonials from './components/Testimonials'
import Footer from './components/Footer'
import { ArrowUpIcon } from '@chakra-ui/icons'
import Technologies from './components/Technologies'
import Terms from './components/Terms'
import Aup from './components/Aup'
import Cookies from './components/Cookies'
import Privacy from './components/Privacy'
import Features from './components/Features'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en'
import es from './locales/es'
import fr from './locales/fr'
import it from './locales/it'
import { useCookies } from 'react-cookie'
const head = require('react-meta-tags')

declare global {
    interface Window {
        Trustpilot: any
    }
}

const App = () => {
	const [cookies, setCookie] = useCookies(['syfras-lang', 'syfras-displaymode'])
	if (!cookies['syfras-lang']) setCookie('syfras-lang', 'en')

	const { colorMode, setColorMode, toggleColorMode } = useColorMode()
	if (!cookies['syfras-displaymode']) {
		if (window.matchMedia('(prefers-color-scheme: light)').matches) {
			setCookie('syfras-displaymode', 'light')
			setColorMode('light')
		} else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			setCookie('syfras-displaymode', 'dark')
			setColorMode('dark')
		}
	}
	if ((cookies['syfras-displaymode'] === 'dark' && colorMode === 'light') || (cookies['syfras-displaymode'] === 'light' && colorMode === 'dark')) toggleColorMode()

	i18n.use(initReactI18next).init({
		resources: {
			en,
			fr,
			es,
			it
		},
		lng: cookies['syfras-lang'],
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		}
	})
	const updateLang = (l: string) => {
		i18n.changeLanguage(l)
		setCookie('syfras-lang', l)
	}

	const navigate = useNavigate()
	const navigateSmoothly = async (label: string = '') => {
		if (window.location.pathname !== '/') navigate('/')
		setTimeout(
			() =>
				window.scrollTo({
					// left: 0,
					top: (document.getElementById(label.toLowerCase())?.offsetTop ?? 0) - 59
				}),
			1
		)
	}

	const [scrollToTopButton, setScrollToTopButton] = useState(false)
	const scrollToTopButtonStyle: any = useColorModeValue('gray.200', 'gray.700')

	window.addEventListener('scroll', () => {
		if (window.scrollY > 0 && !scrollToTopButton) setScrollToTopButton(true)
		else if (window.scrollY === 0 && scrollToTopButton) setScrollToTopButton(false)
	})

	const i18nData: any = i18n.store.data
	const metaTitle: any = i18nData[i18n.language].translation['home.metaTitle']

	return (
		<>
			<head.MetaTags>
				<title>{metaTitle}</title>
			</head.MetaTags>
			<Header updateLang={updateLang} navigateSmoothly={navigateSmoothly} />
			<FullScreen navigateSmoothly={navigateSmoothly} />
			<Pricing />
			<Products />
			<Features />
			<Technologies />
			<Testimonials />
			<Footer />

			{scrollToTopButton ? (
				<Button aria-label='Scroll to top' onClick={() => (document.body.scrollTop = document.documentElement.scrollTop = 0)} backgroundColor={scrollToTopButtonStyle} h={50} w={50} borderRadius={25} position='fixed' bottom='9vh' right='2vw'>
					<ArrowUpIcon />
				</Button>
			) : (
				<></>
			)}
		</>
	)
}

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />
	},
	{
		path: '/terms',
		element: <Terms />
	},
	{
		path: '/privacy',
		element: <Privacy />
	},
	{
		path: '/cookies',
		element: <Cookies />
	},
	{
		path: '/aup',
		element: <Aup />
	}
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<ChakraProvider>
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>
	</ChakraProvider>
)
