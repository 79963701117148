import { ReactNode, useState } from 'react'
import { Box, Stack, HStack, Heading, Text, VStack, useColorModeValue, List, ListItem, ListIcon, Button, Flex, Tab, TabList, Tabs } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

function PriceWrapper({ children }: { children: ReactNode }) {
	return (
		<Box mb={4} shadow='base' borderWidth='1px' alignSelf={{ base: 'center', lg: 'flex-start' }} borderColor={useColorModeValue('gray.200', 'gray.500')} borderRadius={'xl'}>
			{children}
		</Box>
	)
}

const Pricing = () => {
	const { t } = useTranslation()
	const [mode, setMode] = useState<string>('1mo')

	return (
		<Box id='pricing' py={12}>
			<VStack spacing={2} textAlign='center' my='30px'>
				<Heading as='h1' fontSize='4xl'>
					{t('pricing.title')}
				</Heading>
				<Text fontSize='lg' color={'gray.500'}>
					{t('pricing.subtitle')}
				</Text>
			</VStack>
			<Flex alignItems='center' justifyContent='center' my='15px'>
				<Tabs>
					<TabList>
						<Tab color={mode === '1mo' ? '#00fead' : 'gray.500'} onClick={() => setMode('1mo')}>1 {t('pricing.month')}</Tab>
						<Tab color={mode === '1y' ? '#00fead' : 'gray.500'} onClick={() => setMode('1y')}>1 {t('pricing.year')}</Tab>
						<Tab color={mode === '2y' ? '#00fead' : 'gray.500'} onClick={() => setMode('2y')}>2 {t('pricing.years')}</Tab>
					</TabList>
				</Tabs>
			</Flex>
			<Stack direction={{ base: 'column', md: 'row' }} textAlign='center' justify='center' spacing={{ base: 4, lg: 10 }} py={10}>
				<PriceWrapper>
					<Box py={4} px={12}>
						<Text fontWeight='500' fontSize='2xl'>
							{t('pricing.plan1.name')}
						</Text>
						<HStack justifyContent='center'>
							<Text fontSize='5xl' fontWeight='900'>
								{mode === '1mo' ? '3' : mode === '1y' ? '2.50' : '2'}
							</Text>
							<Text fontSize='3xl' fontWeight='600'>
								€
							</Text>
							<Text fontSize='3xl' color='gray.500'>
								/{t('pricing.month')}
							</Text>
						</HStack>
					</Box>
					<VStack bg={useColorModeValue('gray.50', 'gray.700')} py={4} borderBottomRadius={'xl'}>
						<List spacing={3} textAlign='start' px={12}>
							<ListItem>
								<ListIcon as={FaCheckCircle} color='#00fead' />
								{t('pricing.plan1.description1')}
							</ListItem>
							<ListItem>
								<ListIcon as={FaCheckCircle} color='#00fead' />
								{t('pricing.plan1.description2')}
							</ListItem>
							<ListItem>
								<ListIcon as={FaCheckCircle} color='#00fead' />
								{t('pricing.plan1.description3')}
							</ListItem>
						</List>
						<Box w='80%' pt={7}>
							<Button aria-label='Choose plan' onClick={() => window.open('https://client.syfr.as/store/shared-hosting/startup')} w='full' borderColor='#00fead' variant='outline'>
								{t('pricing.ctaText')}
							</Button>
						</Box>
					</VStack>
				</PriceWrapper>

				<PriceWrapper>
					<Box position='relative'>
						<Box position='absolute' top='-16px' left='50%' style={{ transform: 'translate(-50%)' }}>
							<Text textTransform='uppercase' bg={'#00fead'} px={3} py={1} color={'black'} fontSize='sm' fontWeight='600' rounded='xl'>
								{t('pricing.mostPopular')}
							</Text>
						</Box>
						<Box py={4} px={12}>
							<Text fontWeight='500' fontSize='2xl'>
								{t('pricing.plan2.name')}
							</Text>
							<HStack justifyContent='center'>
								<Text fontSize='5xl' fontWeight='900'>
									{mode === '1mo' ? '6' : mode === '1y' ? '4' : '3'}
								</Text>
								<Text fontSize='3xl' fontWeight='600'>
									€
								</Text>
								<Text fontSize='3xl' color='gray.500'>
									/{t('pricing.month')}
								</Text>
							</HStack>
						</Box>
						<VStack bg={useColorModeValue('gray.50', 'gray.700')} py={4} borderBottomRadius={'xl'}>
							<List spacing={3} textAlign='start' px={12}>
								<ListItem>
									<ListIcon as={FaCheckCircle} color='#00fead' />
									{t('pricing.plan2.description1')}
								</ListItem>
								<ListItem>
									<ListIcon as={FaCheckCircle} color='#00fead' />
									{t('pricing.plan2.description2')}
								</ListItem>
								<ListItem>
									<ListIcon as={FaCheckCircle} color='#00fead' />
									{t('pricing.plan2.description3')}
								</ListItem>
								<ListItem>
									<ListIcon as={FaCheckCircle} color='#00fead' />
									{t('pricing.plan2.description4')}
								</ListItem>
								<ListItem>
									<ListIcon as={FaCheckCircle} color='#00fead' />
									{t('pricing.plan2.description5')}
								</ListItem>
							</List>
							<Box w='80%' pt={7}>
								<Button aria-label='Choose plan' onClick={() => window.open('https://client.syfr.as/store/shared-hosting/media')} w='full' bg='#00fead' color='black' _hover={{ color: 'white', backgroundColor: 'gray.900' }}>
									{t('pricing.ctaText')}
								</Button>
							</Box>
						</VStack>
					</Box>
				</PriceWrapper>
				<PriceWrapper>
					<Box py={4} px={12}>
						<Text fontWeight='500' fontSize='2xl'>
							{t('pricing.plan3.name')}
						</Text>
						<HStack justifyContent='center'>
							<Text fontSize='5xl' fontWeight='900'>
								{mode === '1mo' ? '10' : mode === '1y' ? '8' : '6'}
							</Text>
							<Text fontSize='3xl' fontWeight='600'>
								€
							</Text>
							<Text fontSize='3xl' color='gray.500'>
								/{t('pricing.month')}
							</Text>
						</HStack>
					</Box>
					<VStack bg={useColorModeValue('gray.50', 'gray.700')} py={4} borderBottomRadius={'xl'}>
						<List spacing={3} textAlign='start' px={12}>
							<ListItem>
								<ListIcon as={FaCheckCircle} color='#00fead' />
								{t('pricing.plan3.description1')}
							</ListItem>
							<ListItem>
								<ListIcon as={FaCheckCircle} color='#00fead' />
								{t('pricing.plan3.description2')}
							</ListItem>
							<ListItem>
								<ListIcon as={FaCheckCircle} color='#00fead' />
								{t('pricing.plan3.description3')}
							</ListItem>
						</List>
						<Box w='80%' pt={7}>
							<Button aria-label='Choose plan' onClick={() => window.open('https://client.syfr.as/store/shared-hosting/saas')} w='full' borderColor='#00fead' variant='outline'>
								{t('pricing.ctaText')}
							</Button>
						</Box>
					</VStack>
				</PriceWrapper>
			</Stack>
		</Box>
	)
}

export default Pricing
