import { useNavigate } from 'react-router'
import Footer from './Footer'
import Header from './Header'
import { Button, Flex, Heading, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { useCookies } from 'react-cookie'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './../locales/en'
import es from './../locales/es'
import fr from './../locales/fr'
import it from './../locales/it'
import { useState, useEffect } from 'react'
import { ArrowUpIcon } from '@chakra-ui/icons'
const head = require('react-meta-tags')

export default function Privacy(props: any) {
	const navigate = useNavigate()
	const navigateSmoothly = async (label: string = '') => {
		setIsLoaded(false)
		if (window.location.pathname !== '/') navigate('/')
		setTimeout(
			() =>
				window.scrollTo({
					// left: 0,
					top: (document.getElementById(label.toLowerCase())?.offsetTop ?? 0) - 59
				}),
			1
		)
	}

	const [cookies, setCookie] = useCookies(['syfras-displaymode', 'syfras-lang'])
	const { colorMode, setColorMode, toggleColorMode } = useColorMode()
	if (!cookies['syfras-displaymode']) {
		if (window.matchMedia('(prefers-color-scheme: light)').matches) {
			setCookie('syfras-displaymode', 'light')
			setColorMode('light')
		}
		else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			setCookie('syfras-displaymode', 'dark')
			setColorMode('dark')
		}
	}
	if ((cookies['syfras-displaymode'] === 'dark' && colorMode === 'light') || (cookies['syfras-displaymode'] === 'light' && colorMode === 'dark')) toggleColorMode()

	i18n.use(initReactI18next).init({
		resources: {
			en,
			fr,
			es,
			it
		},
		lng: cookies['syfras-lang'],
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		}
	})

	const updateLang = (l: string, i18n: any) => {
		i18n.changeLanguage(l)
		setCookie('syfras-lang', l)
	}

	const [scrollToTopButton, setScrollToTopButton] = useState(false)
	const scrollToTopButtonStyle: any = useColorModeValue('gray.200', 'gray.700')
	const [isLoaded, setIsLoaded] = useState<boolean>(false)
	useEffect(() => {
		if (!isLoaded) {
			document.body.scrollTop = document.documentElement.scrollTop = 0
			setIsLoaded(true)
		}
		window.addEventListener('scroll', () => {
			if (window.scrollY > 0 && !scrollToTopButton) setScrollToTopButton(true)
			else if (window.scrollY === 0 && scrollToTopButton) setScrollToTopButton(false)
		})
	}, [isLoaded, scrollToTopButton])

	const i18nData: any = i18n.store.data
	const metaTitle: any = i18nData[i18n.language].translation['privacy.metaTitle']

	return (
		<>
			<head.MetaTags>
				<title>{metaTitle}</title>
			</head.MetaTags>
			<div style={{ minHeight: 'calc(100vh - 70px)' }}>
				<Header updateLang={updateLang} navigateSmoothly={navigateSmoothly} />
				<Flex direction='column' px={50} pt='90px'>
					<Heading as='h1' fontSize='4xl' mb={5}>
						PRIVACY NOTICE
					</Heading>
					<Text mb={5}>This privacy notice for Ankhrypt Limited (doing business as SYFRAS) ('SYFRAS', 'we', 'us', or 'our'), describes how and why we might collect, store, use, and/or share ('process') your information when you use our services ('Services'), such as when you:</Text>
					<Text mb={5}>Visit our website at https://www.syfras.com, or any website of ours that links to this privacy notice</Text>
					<Text mb={5}>Engage with us in other related ways, including any sales, marketing, or events</Text>
					<Text mb={5}>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at contact@ankhrypt.ltd.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						1. WHAT INFORMATION DO WE COLLECT?
					</Heading>
					<Heading as='h3' fontSize='xl' mb={5}>
						Personal information you disclose to us
					</Heading>
					<Text mb={5}>In Short: We collect personal information that you provide to us.</Text>
					<Text mb={5}>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</Text>
					<Text mb={5}>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</Text>
					<Text>• names</Text>
					<Text>• phone numbers</Text>
					<Text>• email addresses</Text>
					<Text>• mailing addresses</Text>
					<Text>• job titles</Text>
					<Text>• usernames</Text>
					<Text>• passwords</Text>
					<Text>• contact preferences</Text>
					<Text>• billing addresses</Text>
					<Text mb={5}>• contact or authentication data</Text>
					<Text mb={5}>Sensitive Information. We do not process sensitive information.</Text>
					<Text mb={5}>Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Stripe, CoinPayments, Coingate, GoCardless, PerfectMoney, Revolut Pay, Skrill and Paypal. You may find their privacy notice link(s) here: https://stripe.com/privacy, https://www.coinpayments.net/help-privacy, https://coingate.com/privacy, https://gocardless.com/privacy/, https://perfectmoney.is/privacy.html, https://www.revolut.com/privacy-policy/, https://www.skrill.com/en/privacy-notice/ and https://www.paypal.com/myaccount/privacy/privacyhub.</Text>
					<Text mb={5}>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						Information automatically collected
					</Heading>
					<Text mb={5}>In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</Text>
					<Text mb={5}>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</Text>
					<Text mb={5}>Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our Cookie Notice: https://www.syfras.com/cookies.</Text>
					<Text mb={5}>The information we collect includes:</Text>
					<Text mb={5}>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).</Text>
					<Text mb={5}>Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</Text>
					<Text mb={5}>Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						2. HOW DO WE PROCESS YOUR INFORMATION?
					</Heading>
					<Text mb={5}>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</Text>
					<Text mb={5}>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</Text>
					<Text mb={5}>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</Text>
					<Text mb={5}>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</Text>
					<Text mb={5}>To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</Text>
					<Text mb={5}>To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</Text>
					<Text mb={5}>To fulfil and manage your orders. We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.</Text>
					<Text mb={5}>To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</Text>
					<Text mb={5}>To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see 'WHAT ARE YOUR PRIVACY RIGHTS?' below).</Text>
					<Text mb={5}>To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</Text>
					<Text mb={5}>To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
					</Heading>
					<Text mb={5}>In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</Text>
					<Text mb={5}>If you are located in the EU or UK, this section applies to you.</Text>
					<Text mb={5}>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</Text>
					<Text mb={5}>Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click here to learn more.</Text>
					<Text mb={5}>Performance of a Contract. We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</Text>
					<Text mb={5}>Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</Text>
					<Text mb={5}>Send users information about special offers and discounts on our products and services</Text>
					<Text mb={5}>Diagnose problems and/or prevent fraudulent activities</Text>
					<Text mb={5}>Understand how our users use our products and services so we can improve user experience</Text>
					<Text mb={5}>Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</Text>
					<Text mb={5}>Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</Text>
					<Text mb={5}>If you are located in Canada, this section applies to you.</Text>
					<Text mb={5}>We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time. Click here to learn more.</Text>
					<Text mb={5}>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</Text>
					<Text>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</Text>
					<Text>For investigations and fraud detection and prevention</Text>
					<Text>For business transactions provided certain conditions are met</Text>
					<Text>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</Text>
					<Text>For identifying injured, ill, or deceased persons and communicating with next of kin</Text>
					<Text>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</Text>
					<Text>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</Text>
					<Text>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</Text>
					<Text>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</Text>
					<Text>If the collection is solely for journalistic, artistic, or literary purposes</Text>
					<Text mb={5}>If the information is publicly available and is specified by the regulations</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
					</Heading>
					<Text mb={5}>In Short: We may share information in specific situations described in this section and/or with the following third parties.</Text>
					<Text mb={5}>We may need to share your personal information in the following situations:</Text>
					<Text mb={5}>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</Text>
					<Text mb={5}>Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
					</Heading>
					<Text mb={5}>In Short: We may use cookies and other tracking technologies to collect and store your information.</Text>
					<Text mb={5}>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice: https://www.syfras.com/cookies.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						6. HOW LONG DO WE KEEP YOUR INFORMATION?
					</Heading>
					<Text mb={5}>In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</Text>
					<Text mb={5}>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than three (3) months past the termination of the user's account.</Text>
					<Text mb={5}>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						7. HOW DO WE KEEP YOUR INFORMATION SAFE?
					</Heading>
					<Text mb={5}>In Short: We aim to protect your personal information through a system of organisational and technical security measures.</Text>
					<Text mb={5}>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						8. DO WE COLLECT INFORMATION FROM MINORS?
					</Heading>
					<Text mb={5}>In Short: We do not knowingly collect data from or market to children under 18 years of age.</Text>
					<Text mb={5}>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at privacy@syfras.com.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						9. WHAT ARE YOUR PRIVACY RIGHTS?
					</Heading>
					<Text mb={5}>In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</Text>
					<Text mb={5}>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.</Text>
					<Text mb={5}>We will consider and act upon any request in accordance with applicable data protection laws.</Text>
					<Text mb={5}>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</Text>
					<Text mb={5}>If you are located in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.</Text>
					<Text mb={5}>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below or updating your preferences.</Text>
					<Text mb={5}>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</Text>
					<Text mb={5}>Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						Account Information
					</Heading>
					<Text mb={5}>If you would at any time like to review or change the information in your account or terminate your account, you can:</Text>
					<Text>Log in to your account settings and update your user account.</Text>
					<Text mb={5}>Contact us using the contact information provided.</Text>
					<Text mb={5}>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</Text>
					<Text mb={5}>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/. For further information, please see our Cookie Notice: https://www.syfras.com/cookies.</Text>
					<Text mb={5}>If you have questions or comments about your privacy rights, you may email us at privacy@syfras.com.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						10. CONTROLS FOR DO-NOT-TRACK FEATURES
					</Heading>
					<Text mb={5}>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
					</Heading>
					<Text mb={5}>In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</Text>
					<Text mb={5}>California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</Text>
					<Text mb={5}>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						CCPA Privacy Notice
					</Heading>
					<Text mb={5}>The California Code of Regulations defines a 'resident' as:</Text>
					<Text>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</Text>
					<Text>(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</Text>
					<Text mb={5}>All other individuals are defined as 'non-residents'.</Text>
					<Text mb={5}>If this definition of 'resident' applies to you, we must adhere to certain rights and obligations regarding your personal information.</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						What categories of personal information do we collect?
					</Heading>
					<Text mb={5}>We have collected the following categories of personal information in the past twelve (12) months:</Text>
					<Text mb={5}>Category Examples Collected</Text>
					<Text mb={5}>A. Identifiers</Text>
					<Text mb={5}>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>B. Personal information categories listed in the California Customer Records statute</Text>
					<Text mb={5}>Name, contact information, education, employment, employment history, and financial information</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>C. Protected classification characteristics under California or federal law</Text>
					<Text mb={5}>Gender and date of birth</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>D. Commercial information</Text>
					<Text mb={5}>Transaction information, purchase history, financial details, and payment information</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>E. Biometric information</Text>
					<Text mb={5}>Fingerprints and voiceprints</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>F. Internet or other similar network activity</Text>
					<Text mb={5}>Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>G. Geolocation data</Text>
					<Text mb={5}>Device location</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>H. Audio, electronic, visual, thermal, olfactory, or similar information</Text>
					<Text mb={5}>Images and audio, video or call recordings created in connection with our business activities</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>I. Professional or employment-related information</Text>
					<Text mb={5}>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>J. Education Information</Text>
					<Text mb={5}>Student records and directory information</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>K. Inferences drawn from other personal information</Text>
					<Text mb={5}>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>L. Sensitive Personal Information</Text>
					<Text mb={5}>NO</Text>
					<Text mb={5}>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</Text>
					<Text>Receiving help through our customer support channels;</Text>
					<Text>Participation in customer surveys or contests; and</Text>
					<Text mb={5}>Facilitation in the delivery of our Services and to respond to your inquiries.</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						How do we use and share your personal information?
					</Heading>
					<Text mb={5}>More information about our data collection and sharing practices can be found in this privacy notice and our Cookie Notice: https://www.syfras.com/cookies.</Text>
					<Text mb={5}>You may contact us by email at privacy@syfras.com, or by referring to the contact details at the bottom of this document.</Text>
					<Text mb={5}>If you are using an authorised agent to exercise your right to opt out we may deny a request if the authorised agent does not submit proof that they have been validly authorised to act on your behalf.</Text>
					<Text mb={5}>Will your information be shared with anyone else?</Text>
					<Text mb={5}>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the CCPA.</Text>
					<Text mb={5}>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be 'selling' of your personal information.</Text>
					<Text mb={5}>Ankhrypt Limited has not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. Ankhrypt Limited will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						Your rights with respect to your personal data
					</Heading>
					<Text mb={5}>Right to request deletion of the data — Request to delete</Text>
					<Text mb={5}>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</Text>
					<Text mb={5}>Right to be informed — Request to know</Text>
					<Text mb={5}>Depending on the circumstances, you have a right to know:</Text>
					<Text>whether we collect and use your personal information;</Text>
					<Text>the categories of personal information that we collect;</Text>
					<Text>the purposes for which the collected personal information is used;</Text>
					<Text>whether we sell or share personal information to third parties;</Text>
					<Text>the categories of personal information that we sold, shared, or disclosed for a business purpose;</Text>
					<Text>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</Text>
					<Text>the business or commercial purpose for collecting, selling, or sharing personal information; and</Text>
					<Text mb={5}>the specific pieces of personal information we collected about you.</Text>
					<Text mb={5}>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</Text>
					<Text mb={5}>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</Text>
					<Text mb={5}>We will not discriminate against you if you exercise your privacy rights.</Text>
					<Text mb={5}>Right to Limit Use and Disclosure of Sensitive Personal Information</Text>
					<Text mb={5}>We do not process consumer's sensitive personal information.</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						Verification process
					</Heading>
					<Text mb={5}>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</Text>
					<Text mb={5}>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						Other privacy rights
					</Heading>
					<Text mb={5}>You may object to the processing of your personal information.</Text>
					<Text mb={5}>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</Text>
					<Text mb={5}>You can designate an authorised agent to make a request under the CCPA on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with the CCPA.</Text>
					<Text mb={5}>You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.</Text>
					<Text mb={5}>To exercise these rights, you can contact us by email at privacy@syfras.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						12. DO WE MAKE UPDATES TO THIS NOTICE?
					</Heading>
					<Text mb={5}>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</Text>
					<Text mb={5}>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
					</Heading>
					<Text mb={5}>If you have questions or comments about this notice, you may email us at privacy@syfras.com or by post to:</Text>
					<Text>Ankhrypt Limited</Text>
					<Text>84 Strand Street</Text>
					<Text>Skerries, Co. Dublin K34 VW93</Text>
					<Text mb={5}>Ireland</Text>
					<Heading as='h2' fontSize='2xl' mb={5}>
						14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
					</Heading>
					<Text mb={50}>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please submit a request by email to privacy@syfras.com.</Text>
				</Flex>
			</div>

			<Footer />
			{scrollToTopButton ? (
				<Button aria-label='Scroll to top' onClick={() => (document.body.scrollTop = document.documentElement.scrollTop = 0)} backgroundColor={scrollToTopButtonStyle} h={50} w={50} borderRadius={25} position='fixed' bottom='9vh' right='2vw'>
					<ArrowUpIcon />
				</Button>
			) : (
				<></>
			)}
		</>
	)
}
