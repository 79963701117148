import { useTranslation } from 'react-i18next'

import { Container, Heading, Button, VStack, Image, Text, Flex, Box, Progress, useMediaQuery, useTheme, useColorMode, useColorModeValue, Stack } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useState, useCallback, useMemo, useEffect, useLayoutEffect, useRef } from 'react'
import { motion, useAnimation, useMotionValue } from 'framer-motion'

export default function Technologies() {
	const { t } = useTranslation()
	const { colorMode } = useColorMode()

	const technologies = [
		{
			title: t('technologies.techno1.title'),
			logoPath: '/logos/cloud_linux.webp',
			text: t('technologies.techno1.description'),
			w: 250
		},
		{
			title: t('technologies.techno2.title'),
			logoPath: '/logos/litespeed.webp',
			text: t('technologies.techno2.description'),
			w: 250
		},
		{
			title: t('technologies.techno3.title'),
			logoPath: '/logos/plesk.webp',
			text: t('technologies.techno3.description'),
			w: 180
		},
		{
			title: t('technologies.techno4.title'),
			logoPath: '/logos/nginx.webp',
			text: t('technologies.techno4.description'),
			w: 220
		},
		{
			title: t('technologies.techno5.title'),
			logoPath: '/logos/apache.webp',
			text: t('technologies.techno5.description'),
			w: 200
		},
		{
			title: t('technologies.techno6.title'),
			logoPath: '/logos/pagespeed.webp',
			text: t('technologies.techno6.description'),
			w: 250
		},
		{
			title: t('technologies.techno7.title'),
			logoPath: '/logos/wordpress.webp',
			text: t('technologies.techno7.description'),
			w: 300
		},
		{
			title: t('technologies.techno8.title'),
			logoPath: '/logos/php.webp',
			text: t('technologies.techno8.description'),
			w: 170
		},
		{
			title: t('technologies.techno9.title'),
			logoPath: '/logos/nodejs.webp',
			text: t('technologies.techno9.description'),
			w: 160
		},
		{
			title: t('technologies.techno10.title'),
			logoPath: '/logos/ruby.webp',
			text: t('technologies.techno10.description'),
			w: 130
		},
		{
			title: t('technologies.techno11.title'),
			logoPath: colorMode === 'light' ? '/logos/btcpay.webp' : '/logos/btcpay_dark.webp',
			text: t('technologies.techno11.description'),
			w: 200
		},
		{
			title: t('technologies.techno12.title'),
			logoPath: '/logos/docker.webp',
			text: t('technologies.techno12.description'),
			w: 130
		},
		{
			title: t('technologies.techno13.title'),
			logoPath: '/logos/mariadb.webp',
			text: t('technologies.techno13.description'),
			w: 130
		},
		{
			title: t('technologies.techno14.title'),
			logoPath: '/logos/postgresql.webp',
			text: t('technologies.techno14.description'),
			w: 150
		},
		{
			title: t('technologies.techno15.title'),
			logoPath: '/logos/solusvm.webp',
			text: t('technologies.techno15.description'),
			w: 250
		}
	]
	return (
		<div id='technologies'>
			<Box p={4} pt='70px' pb='90px' backgroundColor={useColorModeValue('#F7FAFC', 'black')}>
				<Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'} mb='75px'>
					<Heading fontSize={'3xl'}>{t('technologies.title')}</Heading>
					<Text color={'gray.600'} fontSize={'xl'}>
						{t('technologies.subtitle')}
					</Text>
				</Stack>
				<Container
					py={8}
					px={0}
					maxW={{
						base: '100%',
						sm: '35rem',
						md: '43.75rem',
						lg: '57.5rem',
						xl: '75rem',
						xxl: '87.5rem'
					}}
				>
					<Carousel gap={32}>
						{technologies.map((technology, index) => (
							<Flex
								alignItems='center'
								justifyContent='center'
								key={index}
								// boxShadow='rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
								flexDirection='column'
								overflow='hidden'
								color='gray.300'
								bg='base.d100'
								rounded={5}
								flex={1}
								p={5}
							>
								<Image w={technology.w ?? 150} src={technology.logoPath} alt='Logo' />
								<Box w='100%' h='100%' backgroundColor='transparent' position='absolute'/>
							</Flex>
						))}
					</Carousel>
				</Container>
			</Box>
		</div>
	)
}

const MotionFlex = motion(Flex)

const transitionProps = {
	stiffness: 400,
	type: 'spring',
	damping: 60,
	mass: 3
}

const Carousel = ({ children, gap }: any) => {
	const [trackIsActive, setTrackIsActive] = useState(false)
	const [multiplier, setMultiplier] = useState(0.35)
	const [sliderWidth, setSliderWidth] = useState(0)
	const [activeItem, setActiveItem] = useState(0)
	const [constraint, setConstraint] = useState(0)
	const [itemWidth, setItemWidth] = useState(0)
	const initSliderWidth = useCallback((width: number) => setSliderWidth(width), [])
	const positions = useMemo(() => children.map((_: any, index: number) => -Math.abs((itemWidth + gap) * index)), [children, itemWidth, gap])
	const { breakpoints } = useTheme()
	const [isBetweenBaseAndMd] = useMediaQuery(`(min-width: ${breakpoints.base}) and (max-width: ${breakpoints.md})`)
	const [isBetweenMdAndXl] = useMediaQuery(`(min-width: ${breakpoints.md}) and (max-width: ${breakpoints.xl})`)
	const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`)
	useEffect(() => {
		if (isBetweenBaseAndMd) {
			setItemWidth(sliderWidth - gap)
			setMultiplier(0.65)
			setConstraint(1)
		}
		if (isBetweenMdAndXl) {
			setItemWidth(sliderWidth / 2 - gap)
			setMultiplier(0.5)
			setConstraint(2)
		}
		if (isGreaterThanXL) {
			setItemWidth(sliderWidth / 3 - gap)
			setMultiplier(0.35)
			setConstraint(3)
		}
	}, [isBetweenBaseAndMd, isBetweenMdAndXl, isGreaterThanXL, sliderWidth, gap])
	const sliderProps = {
		setTrackIsActive,
		initSliderWidth,
		setActiveItem,
		activeItem,
		constraint,
		itemWidth,
		positions,
		gap
	}
	const trackProps = {
		setTrackIsActive,
		trackIsActive,
		setActiveItem,
		sliderWidth,
		activeItem,
		constraint,
		multiplier,
		itemWidth,
		positions,
		gap
	}
	const itemProps = {
		setTrackIsActive,
		trackIsActive,
		setActiveItem,
		activeItem,
		constraint,
		itemWidth,
		positions,
		gap
	}
	return (
		<Slider {...sliderProps}>
			<Track {...trackProps}>
				{children.map((child: any, index: number) => (
					<Item {...itemProps} index={index} key={index}>
						{child}
					</Item>
				))}
			</Track>
		</Slider>
	)
}

const Slider = ({ setTrackIsActive, initSliderWidth, setActiveItem, activeItem, constraint, itemWidth, positions, children, gap }: any) => {
	const [ref, { width }]: any = useBoundingRect()
	useLayoutEffect(() => initSliderWidth(Math.round(width)), [width, initSliderWidth])
	const handleFocus = () => setTrackIsActive(true)
	const handleDecrementClick = () => {
		setTrackIsActive(true)
		!(activeItem === positions.length - positions.length) && setActiveItem((prev: any) => prev - 1)
	}
	const handleIncrementClick = () => {
		setTrackIsActive(true)
		!(activeItem === positions.length - constraint) && setActiveItem((prev: any) => prev + 1)
	}
	return (
		<>
			<Box
				ref={ref}
				w={{ base: '100%', md: `calc(100% + ${gap}px)` }}
				ml={{ base: 0, md: `-${gap / 2}px` }}
				px={`${gap / 2}px`}
				position='relative'
				overflow='hidden'
				_before={{
					bgGradient: 'linear(to-r, base.d400, transparent)',
					position: 'absolute',
					w: `${gap / 2}px`,
					content: "''",
					zIndex: 1,
					h: '100%',
					left: 0,
					top: 0
				}}
				_after={{
					bgGradient: 'linear(to-l, base.d400, transparent)',
					position: 'absolute',
					w: `${gap / 2}px`,
					content: "''",
					zIndex: 1,
					h: '100%',
					right: 0,
					top: 0
				}}
			>
				{children}
			</Box>
			<Flex w={`${itemWidth}px`} mt={`${gap}px`} mx='auto'>
				<Button onClick={handleDecrementClick} onFocus={handleFocus} mr={`${gap / 3}px`} color='gray.200' variant='link' minW={0}>
					<ChevronLeftIcon boxSize={9} />
				</Button>
				<Progress
					value={100 / ((positions.length - constraint) / activeItem)}
					alignSelf='center'
					borderRadius='2px'
					bg='base.d100'
					flex={1}
					h='3px'
					sx={{
						'> div': {
							backgroundColor: 'gray.400'
						}
					}}
				/>
				<Button onClick={handleIncrementClick} onFocus={handleFocus} ml={`${gap / 3}px`} color='gray.200' variant='link' zIndex={2} minW={0}>
					<ChevronRightIcon boxSize={9} />
				</Button>
			</Flex>
		</>
	)
}

const Track = ({ setTrackIsActive, trackIsActive, setActiveItem, activeItem, constraint, multiplier, itemWidth, positions, children }: any) => {
	const [dragStartPosition, setDragStartPosition] = useState(0)
	const controls = useAnimation()
	const x = useMotionValue(0)
	const node = useRef(null)
	const handleDragStart = () => setDragStartPosition(positions[activeItem])
	const handleDragEnd = (_: any, info: any) => {
		console.log(info)
		const distance = info.offset.x
		const velocity = info.velocity.x * multiplier
		const direction = velocity < 0 || distance < 0 ? 1 : - 1

		const extrapolatedPosition = dragStartPosition + (direction === 1 ? Math.min(velocity, distance) : Math.max(velocity, distance))

		const closestPosition = positions.reduce((prev: any, curr: any) => {
			return Math.abs(curr - extrapolatedPosition) < Math.abs(prev - extrapolatedPosition) ? curr : prev
		}, 0)

		if (!(closestPosition < positions[positions.length - constraint])) {
			setActiveItem(positions.indexOf(closestPosition))
			controls.start({
				x: closestPosition,
				transition: {
					velocity: info.velocity.x,
					...transitionProps
				}
			})
		} else {
			setActiveItem(positions.length - constraint)
			controls.start({
				x: positions[positions.length - constraint],
				transition: {
					velocity: info.velocity.x,
					...transitionProps
				}
			})
		}
	}
	const handleResize = useCallback(
		(p: any) => {
			controls.start({
				x: p[activeItem],
				transition: {
					...transitionProps
				}
			})
		},
		[activeItem, controls]
	)
	const handleClick = useCallback(
		(event: any) => {
			const currentNode: any = node.current
			currentNode.contains(event.target) ? setTrackIsActive(true) : setTrackIsActive(false)
		},
		[setTrackIsActive]
	)
	const handleKeyDown = useCallback(
		(event: any) => {
			if (trackIsActive) {
				if (activeItem < positions.length - constraint) {
					if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
						event.preventDefault()
						setActiveItem((prev: any) => prev + 1)
					}
				}
				if (activeItem > positions.length - positions.length) {
					if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
						event.preventDefault()
						setActiveItem((prev: any) => prev - 1)
					}
				}
			}
		},
		[trackIsActive, setActiveItem, activeItem, constraint, positions.length]
	)
	useEffect(() => {
		handleResize(positions)

		document.addEventListener('keydown', handleKeyDown)
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
			document.removeEventListener('mousedown', handleClick)
		}
	}, [handleClick, handleResize, handleKeyDown, positions])
	return (
		<>
			{itemWidth && (
				<VStack ref={node} spacing={5} alignItems='stretch'>
					<MotionFlex dragConstraints={node} onDragStart={handleDragStart} onDragEnd={handleDragEnd} animate={controls} style={{ x }} drag='x' _active={{ cursor: 'grabbing' }} minWidth='min-content' flexWrap='nowrap' cursor='grab'>
						{children}
					</MotionFlex>
				</VStack>
			)}
		</>
	)
}

const Item = ({ setTrackIsActive, setActiveItem, activeItem, constraint, itemWidth, positions, children, index, gap }: any) => {
	const [userDidTab, setUserDidTab] = useState(false)
	const handleFocus = () => setTrackIsActive(true)
	const handleBlur = () => {
		userDidTab && index + 1 === positions.length && setTrackIsActive(false)
		setUserDidTab(false)
	}
	const handleKeyUp = (event: any) => event.key === 'Tab' && !(activeItem === positions.length - constraint) && setActiveItem(index)
	const handleKeyDown = (event: any) => event.key === 'Tab' && setUserDidTab(true)
	return (
		<Flex
			onFocus={handleFocus}
			onBlur={handleBlur}
			onKeyUp={handleKeyUp}
			onKeyDown={handleKeyDown}
			w={`${itemWidth}px`}
			_notLast={{
				mr: `${gap}px`
			}}
			py='4px'
		>
			{children}
		</Flex>
	)
}

const debounce = (limit: number, callback: any) => {
	let timeoutId: any
	return (...args: any[]) => {
		if (timeoutId) {
			clearTimeout(timeoutId)
		}
		timeoutId = setTimeout(callback, limit, args)
	}
}

function getDimensionObject(node: any) {
	const rect = node.getBoundingClientRect()
	return {
		width: rect.width,
		height: rect.height,
		top: rect.top,
		left: rect.left,
		x: rect.x,
		y: rect.y,
		right: rect.right,
		bottom: rect.bottom
	}
}

function useBoundingRect(limit: any = null) {
	const [dimensions, setDimensions] = useState({})
	const [node, setNode] = useState(null)
	const ref = useCallback((node: any) => {
		setNode(node)
	}, [])
	useLayoutEffect(() => {
		if ('undefined' !== typeof window && node) {
			const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)))
			measure()
			const listener = debounce(limit ? limit : 100, measure)
			window.addEventListener('resize', listener)
			window.addEventListener('scroll', listener)
			return () => {
				window.removeEventListener('resize', listener)
				window.removeEventListener('scroll', listener)
			}
		}
	}, [node, limit])
	return [ref, dimensions, node]
}
