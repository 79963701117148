import { useNavigate } from 'react-router'
import Footer from './Footer'
import Header from './Header'
import { Button, Flex, Heading, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { useCookies } from 'react-cookie'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './../locales/en'
import es from './../locales/es'
import fr from './../locales/fr'
import it from './../locales/it'
import { ArrowUpIcon } from '@chakra-ui/icons'
import { useState, useEffect } from 'react'
const head = require('react-meta-tags')

export default function Aup(props: any) {
	const navigate = useNavigate()
	const navigateSmoothly = async (label: string = '') => {
		setIsLoaded(false)
		if (window.location.pathname !== '/') navigate('/')
		setTimeout(
			() =>
				window.scrollTo({
					// left: 0,
					top: (document.getElementById(label.toLowerCase())?.offsetTop ?? 0) - 59
				}),
			1
		)
	}

	const [cookies, setCookie] = useCookies(['syfras-displaymode', 'syfras-lang'])
	const { colorMode, setColorMode, toggleColorMode } = useColorMode()
	if (!cookies['syfras-displaymode']) {
		if (window.matchMedia('(prefers-color-scheme: light)').matches) {
			setCookie('syfras-displaymode', 'light')
			setColorMode('light')
		}
		else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			setCookie('syfras-displaymode', 'dark')
			setColorMode('dark')
		}
	}
	if ((cookies['syfras-displaymode'] === 'dark' && colorMode === 'light') || (cookies['syfras-displaymode'] === 'light' && colorMode === 'dark')) toggleColorMode()

	i18n.use(initReactI18next).init({
		resources: {
			en,
			fr,
			es,
			it
		},
		lng: cookies['syfras-lang'],
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		}
	})

	const updateLang = (l: string, i18n: any) => {
		i18n.changeLanguage(l)
		setCookie('syfras-lang', l)
	}

	const [scrollToTopButton, setScrollToTopButton] = useState(false)
	const scrollToTopButtonStyle: any = useColorModeValue('gray.200', 'gray.700')
	const [isLoaded, setIsLoaded] = useState<boolean>(false)
	useEffect(() => {
		if (!isLoaded) {
			document.body.scrollTop = document.documentElement.scrollTop = 0
			setIsLoaded(true)
		}
		window.addEventListener('scroll', () => {
			if (window.scrollY > 0 && !scrollToTopButton) setScrollToTopButton(true)
			else if (window.scrollY === 0 && scrollToTopButton) setScrollToTopButton(false)
		})
	}, [isLoaded, scrollToTopButton])

	const i18nData: any = i18n.store.data
	const metaTitle: any = i18nData[i18n.language].translation['aup.metaTitle']

	return (
		<>
			<head.MetaTags>
				<title>{metaTitle}</title>
			</head.MetaTags>
			<div style={{ minHeight: 'calc(100vh - 70px)' }}>
				<Header updateLang={updateLang} navigateSmoothly={navigateSmoothly} />
				<Flex direction='column' px={50} pt='90px'>
					<Heading as='h1' fontSize='4xl' mb={5}>
						User Acceptance Policy
					</Heading>

					<Text mb={5}>This Acceptable Use Policy ('Policy') is part of our Terms and Conditions ('Legal Terms') and should therefore be read alongside our main Legal Terms: https://www.syfras.com/terms. If you do not agree with these Legal Terms, please refrain from using our Services. Your continued use of our Services implies acceptance of these Legal Terms.</Text>
					<Text mb={5}>Please carefully review this Policy which applies to any and all:</Text>
					<Text>(a) uses of our Services (as defined in 'Legal Terms') and</Text>
					<Text mb={5}>(b) forms, materials, consent tools, comments, post, and all other content available on the Services ('Content')</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						WHO WE ARE
					</Heading>
					<Text mb={5}>We are Ankhrypt Limited, doing business as SYFRAS ('Company','we', 'us', or 'our') a company registered in Ireland at 84 Strand Street, Skerries, Co. Dublin K34 VW93. We operate the website https://www.syfras.com (the 'Site'), as well as any other related products and services that refer or link to this Policy (collectively, the 'Services' ).</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						USE OF THE SERVICES
					</Heading>
					<Text mb={5}>When you use the Services you warrant that you will comply with this Policy and with all applicable laws.</Text>
					<Text mb={5}>You also acknowledge that you may not:</Text>
					<Text>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</Text>
					<Text>Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretences.</Text>
					<Text>Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.</Text>
					<Text>Engage in unauthorised framing of or linking to the Services.</Text>
					<Text>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</Text>
					<Text>Make improper use of our Services, including our support services or submit false reports of abuse or misconduct.</Text>
					<Text>Engage in any automated use of the Services, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</Text>
					<Text>Interfere with, disrupt, or create an undue burden on the Services or the networks or the Services connected.</Text>
					<Text>Attempt to impersonate another user or person or use the username of another user.</Text>
					<Text>Use any information obtained from the Services in order to harass, abuse, or harm another person.</Text>
					<Text>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavour or commercial enterprise.</Text>
					<Text>Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services, except as expressly permitted by applicable law.</Text>
					<Text>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</Text>
					<Text>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</Text>
					<Text>Delete the copyright or other proprietary rights notice from any Content.</Text>
					<Text>Copy or adapt the Services’ software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</Text>
					<Text>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.</Text>
					<Text>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ('gifs'), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'pcms').</Text>
					<Text>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or using or launching any unauthorised script or other software.</Text>
					<Text>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</Text>
					<Text>Use the Services in a manner inconsistent with any applicable laws or regulations.</Text>
					<Text mb={5}>Sell or otherwise transfer your profile.</Text>
					<Text mb={5}>If you subscribe to our Services, you understand, acknowledge, and agree that you may not, except if expressly permitted:</Text>
					<Text>Engage in any use, including modification, copying, redistribution, publication, display, performance, or retransmission, of any portions of any Services, other than as expressly permitted by this Policy, without the prior written consent of Ankhrypt Limited, which consent Ankhrypt Limited may grant or refuse in its sole and absolute discretion.</Text>
					<Text>Provide, or otherwise make available, the Services to any third party.</Text>
					<Text>Intercept any data not intended for you.</Text>
					<Text>Damage, reveal, or alter any user's data, or any other hardware, software, or information relating to another person or entity.</Text>
					<Text>Send unwanted communications of any kind.</Text>
					<Text>Perform activities that are illegal in Ireland or your own country.</Text>
					<Text>Perform any activity that could result in the blacklisting of one or more of the service's IP addresses.</Text>
					<Text>Publish racist, anti-semitic or otherwise hateful content directed at a group or individual.</Text>
					<Text mb={5}>Publish any content subject to copyright to which you do not own the full rights.</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						CONSEQUENCES OF BREACHING THIS POLICY
					</Heading>
					<Text mb={5}>The consequences for violating our Policy will vary depending on the severity of the breach and the user's history on the Services, by way of example:</Text>
					<Text mb={5}>We may, in some cases, give you a warning, however, if your breach is serious or if you continue to breach our Legal Terms and this Policy, we have the right to suspend terminate your access to and use of our Services and, if applicable, disable your account. We may also notify law enforcement or issue legal proceedings against you when we believe that there is a genuine risk to an individual or a threat to public safety.</Text>
					<Text mb={5}>We exclude our liability for all action we may take in response to any of your breaches of this Policy.</Text>
					<Heading as='h3' fontSize='xl' mb={5}>
						HOW CAN YOU CONTACT US ABOUT THIS POLICY?
					</Heading>
					<Text mb={5}>If you have any further questions or comments, you may contact us by:</Text>
					<Text>Email: contact@syfras.com</Text>
					<Text mb={50}>Online contact form: https://client.syfr.as/contact.php</Text>
				</Flex>
			</div>

			<Footer />
			{scrollToTopButton ? (
				<Button aria-label='Scroll to top' onClick={() => (document.body.scrollTop = document.documentElement.scrollTop = 0)} backgroundColor={scrollToTopButtonStyle} h={50} w={50} borderRadius={25} position='fixed' bottom='9vh' right='2vw'>
					<ArrowUpIcon />
				</Button>
			) : (
				<></>
			)}
		</>
	)
}
