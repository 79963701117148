// import { ReactNode } from 'react'
import {
	Box,
	useColorMode,
	// Flex, Heading, Text, Stack, Container, Avatar, useColorModeValue
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
// import { useTranslation } from 'react-i18next'

// const Testimonial = ({ children }: { children: ReactNode }) => {
// 	return <Box>{children}</Box>
// }

// const TestimonialContent = ({ children }: { children: ReactNode }) => {
// 	return (
// 		<Stack
// 			bg={useColorModeValue('white', '#2D3748')}
// 			boxShadow={'lg'}
// 			p={8}
// 			rounded={'xl'}
// 			align={'center'}
// 			pos={'relative'}
// 			_after={{
// 				content: `""`,
// 				w: 0,
// 				h: 0,
// 				borderLeft: 'solid transparent',
// 				borderLeftWidth: 16,
// 				borderRight: 'solid transparent',
// 				borderRightWidth: 16,
// 				borderTop: 'solid',
// 				borderTopWidth: 16,
// 				borderTopColor: useColorModeValue('white', '#2D3748'),
// 				pos: 'absolute',
// 				bottom: '-16px',
// 				left: '50%',
// 				transform: 'translateX(-50%)'
// 			}}
// 		>
// 			{children}
// 		</Stack>
// 	)
// }

// const TestimonialHeading = ({ children }: { children: ReactNode }) => {
// 	return (
// 		<Heading as={'h3'} fontSize={'xl'}>
// 			{children}
// 		</Heading>
// 	)
// }

// const TestimonialText = ({ children }: { children: ReactNode }) => {
// 	return (
// 		<Text textAlign={'center'} color={useColorModeValue('gray.600', 'gray.400')} fontSize={'sm'}>
// 			{children}
// 		</Text>
// 	)
// }

// const TestimonialAvatar = ({ src, name, title }: { src: string; name: string; title: string }) => {
// 	return (
// 		<Flex align={'center'} mt={8} direction={'column'}>
// 			<Avatar src={src} mb={2} />
// 			<Stack spacing={-1} align={'center'}>
// 				<Text fontWeight={600}>{name}</Text>
// 				<Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
// 					{title}
// 				</Text>
// 			</Stack>
// 		</Flex>
// 	)
// }

const Testimonials = (props: any) => {
	const { colorMode } = useColorMode()
	const [cookies] = useCookies()
	const [state, updateState] = useState<any>('')
	const forceUpdate = useCallback(() => updateState(''), [])
	useEffect(() => {
		if (window.Trustpilot) window.Trustpilot.loadFromElement(document.getElementById('trustpilot'))
		forceUpdate()
	}, [forceUpdate])
	return (
		<Box
			id='testimonials'
			// backgroundColor={useColorModeValue('white', '#1A202C')}
		>
		{state}

			{/* <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
				<Stack spacing={0} align={'center'} mb='20px'>
					<Heading display='flex'>{t('testimonials.title.part1')}<Text
						// style={{textShadow: '0.5px 0.5px grey, -0.5px -0.5px grey, 0.5px -0.5px grey, -0.5px 0.5px grey'}}
						color='#00fead' ml='8px'>{t('testimonials.title.part2')}</Text></Heading>
					<Text>{t('testimonials.subtitle')}</Text>
				</Stack>
				<Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 10, md: 4, lg: 10 }}>
					<Testimonial>
						<TestimonialContent>
							<TestimonialHeading>Efficient Collaborating</TestimonialHeading>
							<TestimonialText>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.</TestimonialText>
						</TestimonialContent>
						<TestimonialAvatar src={'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'} name={'Jane Cooper'} title={'CEO at ABC Corporation'} />
					</Testimonial>
					<Testimonial>
						<TestimonialContent>
							<TestimonialHeading>Intuitive Design</TestimonialHeading>
							<TestimonialText>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.</TestimonialText>
						</TestimonialContent>
						<TestimonialAvatar src={'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'} name={'Jane Cooper'} title={'CEO at ABC Corporation'} />
					</Testimonial>
					<Testimonial>
						<TestimonialContent>
							<TestimonialHeading>Mindblowing Service</TestimonialHeading>
							<TestimonialText>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.</TestimonialText>
						</TestimonialContent>
						<TestimonialAvatar src={'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'} name={'Jane Cooper'} title={'CEO at ABC Corporation'} />
					</Testimonial>
				</Stack>
			</Container> */}
			<div style={{ display: colorMode === 'light' && cookies['syfras-lang'] === 'fr' ? 'flex' : 'none'}} data-theme='light' id='trustpilot' className='trustpilot-widget' data-locale='fr' data-template-id='5419b6a8b0d04a076446a9ad' data-businessunit-id='63a5e28eb3f678ebda100eb4' data-style-height='24px' data-style-width='100%' data-min-review-count='0' data-without-reviews-preferred-string-id='1' data-style-alignment='center'>
				<div />
			</div>
			<div style={{ display: colorMode === 'dark' && cookies['syfras-lang'] === 'fr' ? 'flex' : 'none'}} data-theme='dark' id='trustpilot' className='trustpilot-widget' data-locale='fr' data-template-id='5419b6a8b0d04a076446a9ad' data-businessunit-id='63a5e28eb3f678ebda100eb4' data-style-height='24px' data-style-width='100%' data-min-review-count='0' data-without-reviews-preferred-string-id='1' data-style-alignment='center'>
				<div />
			</div>

			<div style={{ display: colorMode === 'light' && cookies['syfras-lang'] === 'en' ? 'flex' : 'none'}} data-theme='light' id='trustpilot' className='trustpilot-widget' data-locale='en-IE' data-template-id='5419b6a8b0d04a076446a9ad' data-businessunit-id='63a5e28eb3f678ebda100eb4' data-style-height='24px' data-style-width='100%' data-min-review-count='0' data-without-reviews-preferred-string-id='1' data-style-alignment='center'>
				<div />
			</div>
			<div style={{ display: colorMode === 'dark' && cookies['syfras-lang'] === 'en' ? 'flex' : 'none'}} data-theme='dark' id='trustpilot' className='trustpilot-widget' data-locale='en-IE' data-template-id='5419b6a8b0d04a076446a9ad' data-businessunit-id='63a5e28eb3f678ebda100eb4' data-style-height='24px' data-style-width='100%' data-min-review-count='0' data-without-reviews-preferred-string-id='1' data-style-alignment='center'>
				<div />
			</div>

			<div style={{ display: colorMode === 'light' && cookies['syfras-lang'] === 'es' ? 'flex' : 'none'}} data-theme='light' id='trustpilot' className='trustpilot-widget' data-locale='es-ES' data-template-id='5419b6a8b0d04a076446a9ad' data-businessunit-id='63a5e28eb3f678ebda100eb4' data-style-height='24px' data-style-width='100%' data-min-review-count='0' data-without-reviews-preferred-string-id='1' data-style-alignment='center'>
				<div />
			</div>
			<div style={{ display: colorMode === 'dark' && cookies['syfras-lang'] === 'es' ? 'flex' : 'none'}} data-theme='dark' id='trustpilot' className='trustpilot-widget' data-locale='es-ES' data-template-id='5419b6a8b0d04a076446a9ad' data-businessunit-id='63a5e28eb3f678ebda100eb4' data-style-height='24px' data-style-width='100%' data-min-review-count='0' data-without-reviews-preferred-string-id='1' data-style-alignment='center'>
				<div />
			</div>
		</Box>
	)
}

export default Testimonials
