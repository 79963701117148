import { Box, Container, Stack, Text, useColorModeValue, Input, Button, Flex, chakra, VisuallyHidden } from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import { BiMailSend } from 'react-icons/bi'
import { FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import jsonp from 'jsonp'
import { useTranslation } from 'react-i18next'

const SocialButton = ({ children, label, href }: { children: ReactNode; label: string; href: string }) => {
	return (
		<chakra.button
			bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
			rounded={'full'}
			w={8}
			h={8}
			cursor={'pointer'}
			as={'a'}
			href={href}
			display={'inline-flex'}
			alignItems={'center'}
			justifyContent={'center'}
			transition={'background 0.3s ease'}
			_hover={{
				bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200')
			}}
			target='_blank'
		>
			<VisuallyHidden>{label}</VisuallyHidden>
			{children}
		</chakra.button>
	)
}

export default function Footer(props: any) {
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [subEmail, setSubEmail] = useState<string>('')
	return (
		<Box bg={useColorModeValue('gray.50', 'gray.900')} color={useColorModeValue('gray.700', 'gray.200')} py={3}>
			<Container as={Stack} py={'2.5px'} px={10} direction={{ base: 'column', md: 'row' }} spacing={4} align={{ base: 'center', md: 'center' }} justifyContent='space-between' maxW='100%'>
				<Text>© {new Date().getFullYear()} Ankhrypt Ltd. {t('footer.copyrightText')}</Text>
				<Stack direction={'row'} spacing={6}>
					<SocialButton label={'Twitter'} href={'https://twitter.com/syfrascom'}>
						<FaTwitter />
					</SocialButton>
					<SocialButton label={'Instagram'} href={'https://instagram.com/syfrascom'}>
						<FaInstagram />
					</SocialButton>
					<SocialButton label={'LinkedIn'} href={'https://linkedin.com/company/ankhrypt'}>
						<FaLinkedin />
					</SocialButton>
				</Stack>
				<Stack direction={'row'} spacing={5} fontSize={14} textAlign='center'>
					<Link to='/terms'>{t('footer.item1')}</Link>
					<Link to='/privacy'>{t('footer.item2')}</Link>
					<Link to='/cookies'>{t('footer.item3')}</Link>
					<Link to='/aup'>{t('footer.item4')}</Link>
				</Stack>

				<Stack direction={'row'}>
					<Input
						placeholder={t('footer.subscriptionInput.placeholder') || ''}
						_placeholder={{ color: useColorModeValue('black', 'white') }}
						bg={useColorModeValue('blackAlpha.100', '#2D3748')}
						border={0}
						_focus={{
							bg: 'whiteAlpha.300',
							border: '1px solid #00fead',
							boxShadow: 'none'
						}}
						onChange={(e) => setSubEmail(e.target.value)}
					/>
					<Button
						bg={'#00fead'}
						color={'black'}
						// _hover={{
						// 	bg: 'green.600'
						// }}
						disabled={isLoading}
						onClick={(e) => {
							setIsLoading(true)
							try {
								jsonp('https://syfras.us21.list-manage.com/subscribe/post-json?u=8b43b928b30fdec9f301c86ea&amp;id=cdca402308&amp;f_id=00bdc0e1f0&EMAIL=' + subEmail.replace('@', '%40') + '&c=__jp0', { param: 'c' }, (err: any, data: any) => {
									if (data && data.msg && data.msg.includes('already')) alert('You are already subscribed!')
									else if (data && data.result && data.result === 'success') {
										alert('Thanks for subscribing to Syfras!')
										setSubEmail('')
									} else alert('Internal server error. Please try again')
								})
							} catch (err) {
								alert('Internal server error. Please try again')
							}
							setIsLoading(false)
						}}
						_hover={{ color: 'white', backgroundColor: 'gray.700' }}
						aria-label='Subscribe to newsletter'
					>
						<Flex justifyContent='center' alignItems='center' pl='27.5px' pr='30px'>
							<BiMailSend />
							<Text ml='5px'>{t('footer.subscriptionCta.text')}</Text>
						</Flex>
					</Button>
				</Stack>
			</Container>
		</Box>
	)
}
