import { ReactElement } from 'react'
import { Box, Icon, Text, Flex, Heading, Grid } from '@chakra-ui/react'
import { FcAdvance, FcBriefcase, FcDataBackup, FcDataEncryption, FcGlobe } from 'react-icons/fc'
import { useTranslation } from 'react-i18next'

interface FeatureProps {
	title: string
	text: string
	icon: ReactElement
}

const Feature = ({ title, text, icon }: FeatureProps) => {
	return (
		<Flex direction='column' alignItems='center' maxW="30%" textAlign='center' px='5%' mb='50px'>
			<Flex w={16} h={16} align={'center'} justify={'center'} color={'white'} rounded={'full'} bg={'gray.100'} mb={3}>
				{icon}
			</Flex>
			<Text fontWeight={600} mb={2}>{title}</Text>
			<Text color={'gray.600'}>{text}</Text>
		</Flex>
	)
}

export default function Features() {
	const { t } = useTranslation()

	return (
		<Box id='features' p={4} pb='40px' pt='65px'>
			<Box textAlign='center'>
				<Heading as='h1' fontSize='4xl' mb='20px'>
					{t('features.title')}
				</Heading>
				<Text fontSize='lg' color={'gray.500'} px='28%' mb='80px'>
					{t('features.subtitle')}
				</Text>
			</Box>

			<Grid display='flex' flexWrap='wrap' justifyContent='center'>
				<Feature icon={<Icon as={FcDataBackup} w={10} h={10} />} title={t('features.feature1.title')} text={t('features.feature1.description')} />
				<Feature icon={<Icon as={FcGlobe} w={10} h={10} />} title={t('features.feature2.title')} text={t('features.feature2.description')} />
				<Feature icon={<Icon as={FcBriefcase} w={10} h={10} />} title={t('features.feature3.title')} text={t('features.feature3.description')} />
				<Feature icon={<Icon as={FcDataEncryption} w={10} h={10} />} title={t('features.feature4.title')} text={t('features.feature4.description')} />
				<Feature icon={<Icon as={FcAdvance} w={10} h={10} />} title={t('features.feature5.title')} text={t('features.feature5.description')} />
			</Grid>
		</Box>
	)
}
